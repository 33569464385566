import { useQuery } from '@tanstack/react-query';
import { Element } from 'react-scroll';


import Item from 'src/pages/categories/List/Item/Item';
import SkeletonItem from 'src/pages/categories/List/Item/Skeleton';

import { Error as MyError, Title } from 'src/components'
import BASE_URL from 'src/constants/vars';
import Styled from './Styled.styled';

export type CategoryType = {
    id: number,
    title: string,
    image: string
}

function Categories({ hideTitle }: { hideTitle?: boolean }) {
    const featchCategory = async () => {
        const res = await fetch(`${BASE_URL}/products/categories/`);
        const jsonData = await res.json();
        return jsonData
    }

    const { data, error, isLoading, isError, isFetching } = useQuery({ queryKey: ['coursesData'], queryFn: () => featchCategory(), keepPreviousData: true })


    let Comp = null

    if (isError) {
        let msg: string = "حدث خطأ ما"
        if (error instanceof Error) {
            msg = error.message;
        }
        Comp = <MyError msg={msg} />
    }
    else {

        Comp = isLoading || isFetching ?
            <>
                {Array.from(Array(6).keys()).map((item) => (
                    <div key={`skl-${item}`} className='col-lg-4 col-sm-6 col-9 mx-auto mx-sm-0 mb-2' >
                        <SkeletonItem />
                    </div>
                ))
                }
            </ >
            :
            data && data !== undefined ?
                data.length > 0 ?
                    data.map((item: CategoryType) => (
                        <div key={`cat-${item.id}`} className='col-lg-4 col-sm-6 col-9 mx-auto mx-sm-0 mb-2'>
                            <Item item={item} />
                        </div>

                    ))
                    :
                    <p className='not-found'>
                        لم يتم إضافة أي أصناف
                    </p>
                :
                null

    }


    return (

        <Styled>
            <Element name='categoriesElement'>
                <div className='container'>
                    <div className='row'>
                        {hideTitle ? null :
                            <div className='col-12'>
                                <Title title={"الأصناف"} />
                            </div>}

                        {Comp}

                    </div>
                </div>
            </Element>
        </Styled >
    )
}

export default Categories