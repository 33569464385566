function Title(props: { title: string, color?: string }) {
    return (
        <h2 className='main-title' style={{ color: props.color }}>
            {props.title}
            <div className='outline'>
                <div className='inline'>
                </div>
            </div>
        </h2>
    )
}

export default Title