import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';


import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "ar",
        fallbackLng: 'ar',
        supportedLngs: ["en", "ar"],
        resources: {
            en: {
                translation: enTranslation
            },
            ar: {
                translation: arTranslation
            }
        },
        debug: process.env.NODE_ENV === "development",

    });


export default i18n;