import { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


import Nav from './Nav/Nav';


const Footer = lazy(() => import('./Footer/Footer'));


function Layout() {
    const { i18n } = useTranslation();

    return (
        <div className={['abs-con', i18n.language === 'ar' ? 'ar' : ''].join(' ')} >
            <header>
                <Nav />
            </header>

            <div className='content'>
                <Outlet />
            </div>

            <Suspense fallback={<div>Loading ...</div>}>
                <Footer />
            </Suspense>
            <ToastContainer closeButton={false} />
        </div>
    )
}

export default Layout