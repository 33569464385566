import { styled } from 'styled-components';


export default styled.nav`
    background: initial;
    left: 0;
    padding: .75rem 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    color: #fff;
    height: 70px;
    display: flex;
    align-items: center;

    .nav__content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;

        img{
            height: fit-content;
            width: 130px;
            cursor: pointer;
        }

        .menu__btn{
            height: 32px;
            width: 32px;

            div{
                cursor: pointer;
            }
        }
    } 
    
    @media screen and (max-width:576px){
        .nav__content{
            padding: 0 1rem;
        }

    }

`;

export const AppBar = styled.div`
    &>div:first-child{
        position: fixed;
        height: 100vh;
        top: 0;
        right: -330px;
        background-color: #111111ed;
        background-clip: padding-box;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 330px;
        outline: 0;
        transition: right .4s ease-in-out;
        z-index: 1001;

        header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #222;
            margin: 1.1rem 1.5rem;
            padding: 1rem .3rem 1.2rem 0;

            .img__container{
                width: 112px;
                height: 41px;

                img{
                    height: fit-content;
                    width: 100%;
                }
            }

            .icon__container{
                height: 25px;
                width: 25px;
                cursor: pointer;
                color: #ddd;
            }
        }
    }

    .overlay{
        background-color: #000;
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 1000;
        visibility:hidden;
        opacity: 0;
        transition: all .4s ease;
    }

    .show__overlay{
        opacity: 0.5 !important;
        visibility: visible !important;
    }

    .show__menu{
        right: 0 !important;
    }
`;

export const NavMenu = styled.div`
    padding: 0 1rem;
    
    .nav__item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0.8rem 1rem 0.6rem;
        transition: color .2s ease;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 400;
        letter-spacing: .25px;
        font-size: 1rem;
        
        
        &:hover,
        &:active,
        &:focus{
            color: var(--primary);
        }
       

        .icon{
            height: 25px;
            width: 25px;

            &>div{
                width: 100%;
                height: 100%;
                svg{
                    width: 100%;
                    height: 100%;
                }

            }


        }
        
        .text{
            font-family: var(--ft);
        }
    }
    .search__form{
        color: var(--primary);
        font-family: var(--ft);
        position: relative;
        padding: 0 0.4rem;
        margin-top: 1.25rem;
        margin-left: 2px;

     
        input{
            border-radius: 2rem;
            padding: .75rem 1.25rem;
            border: unset;
            width: 100%;
            outline: unset;
            font-family: var(--ft);
            background: #262525;
            color: #eee;
            font-weight: 300;
            letter-spacing: .25px;
            
            &::placeholder{
                font-weight: bold;
                color: #fff;
            }
        
        }

        button{
            cursor: pointer;
            align-items: center;
            background-color: initial;
            border: initial;
            color: #919191;
            display: flex;
            overflow: initial;
            position: absolute;
            transition: color .4s ease;
            height: 30px;
            width: 30px;
            top: 12px;
            left: 21px;

            &:hover,
            &:focus,
            &:active{
                color: var(--primary);
            }

            svg{
                width: 100%;
                height:100%;
            }
        }
    }

`;


