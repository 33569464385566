import { styled } from 'styled-components';



export default styled.section`
    padding: 5rem 9rem;
     
    @media (max-width: 1160px){
        padding: 5rem 4rem;
    }

    @media (max-width: 991px){
        padding: 5rem 3rem; 
    }

    @media (max-width: 767px){
        padding: 4rem 0;

    }
   

`