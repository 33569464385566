import { Routes, Route } from 'react-router-dom';

import 'react-loading-skeleton/dist/skeleton.css'

import './setup/i18n/i18n';

import { Home, Products, CatsList, ProductsSerch } from './pages';
import Layout from './layouts/Layout/Layout';
import { Page404 } from 'src/components';



function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path='categories' >
					<Route index element={<CatsList />} />
					<Route path=':slug' element={<Products />} />
				</Route >
				<Route path='products' >
					<Route path='search' element={<ProductsSerch />} />
				</Route >


				<Route path="*" element={<Page404 />} />
			</Route>
		</Routes>
	);
}

export default App;
