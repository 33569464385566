import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Categories from './Components/Categories/Categories';


function Home() {
    return (
        <>
            <Hero />
            <Categories />
            <About />
        </>
    )
}

export default Home