import Skeleton from 'react-loading-skeleton';


function Item(props: any) {
    const item = props.item;

    return (<div className='product mb-4'>
        <div className='img-con'>
            {item ? <img src={item.image} alt={item.name} height={140} /> :

                <Skeleton height={'100%'} />}

        </div>
        <div className='content'>
            <h4>
                {item ? item.name : <Skeleton height={30} />}
            </h4>
            <p className="cat">
                {item ? props.catName || item.category : <Skeleton height={15} width={'80%'} />}
            </p>

            <div>
                {item ?
                    <div className='price'>
                        <div className={item.discount_price < 1 ? 'original' : 'original_discount'}>
                            {item.original_price}
                            <span className="me-1">
                                دينار
                            </span>
                        </div>
                        {item.discount_price > 0 ?
                            <div className='discount'>
                                {item.discount_price}
                                <span className="me-1">
                                    دينار
                                </span>
                            </div>
                            : null
                        }</div> : <Skeleton height={28} width={"50%"} />}
            </div>


        </div>
    </div>
    )
}


export default Item;