import { styled } from 'styled-components';


export default styled.section`
    padding: 4.5rem 0 8rem;
    background: #9898980D;
    
    .img-con{
        position: relative;
        margin: auto;
        width: 300px;
        height: 300px;

        img{
            width: 100%;
            height: 100%;
        }
    }

    .content-con{
        display: flex;
        align-items: center;
    }

    .title{
        margin-top: 0;
        font-family: var(--ft);
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: right;
        letter-spacing: 0.02em;
        color: #222222;
        font-weight: 600;
        word-spacing: -0.2rem;
        margin-bottom: 1.25rem;
    }

    .text{
        font-family: var(--ft);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 250%;
        text-align: right;
        letter-spacing: 0.03em;
        color: #666;
        letter-spacing: .5px;
    }

    @media (max-width: 991px){
        padding: 4rem 0 5rem;
            
        .img-con{
            margin-bottom: 1.75rem;
            width: 350px ;
            height: 325px;
        
            img{
                border-radius:1rem;
            }

            span{
                display: none;
            }
        }

            .content-con{
                .content{
                    .text{
                        text-align: center;
                    }
                }
            }
        }

    /* @media (max-width: 767px){} */

    @media (max-width: 576px){
        padding: 3rem 1.25rem 5rem;

        .img-con{
            width: 290px ;
            height: 260px;
        
            img{
                border-radius:1rem;
            }

            span{
                display: none;
            }
        }

        .content-con{
            .content{
                .title{
                    font-size: 2.75rem;
                    margin-bottom: .75rem;
                }

                .text{
                    line-height: 224%;
                    font-size: 17px;
                }

            }
        }

    }

`