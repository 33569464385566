import Image from 'src/assets/images/products.png';
import Styled from './Styled.styled';
import { Title } from 'src/components';
import CatsList from './List/List';

function List() {
    return (
        <Styled>
            <header>
                <div className='title  products-title'>
                    <Title title={"الأصناف"} color={"#000"} />
                </div>
                <img src={Image} alt={"Qadis Coffee"} />
            </header>

            <CatsList />

        </Styled>
    )
}

export default List