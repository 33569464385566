import { useState } from 'react';
import Nav, { AppBar, NavMenu } from './Styled.styled';
import { useNavigate } from 'react-router-dom';
import logo from 'src/assets/svg/logo.svg';
import sm_logo from 'src/assets/images/menu_logo.avif';
import {
    Menu, XMark, Home, Clipboard,
    Map, Facebook, Search, Instagram
} from 'src/components/icons';


const items = [
    {

        name: "الرئيسية",
        path: "/",
        redirect: false,
        icon: Home
    },
    {

        name: " القائمة",
        path: "/categories",
        redirect: false,
        icon: Clipboard
    },
    {

        name: "موقعنا",
        path: "https://maps.app.goo.gl/Y1726dtkNqN8eRn68",
        redirect: true,
        icon: Map
    },
    {

        name: "صفحة الفيسبوك",
        path: "https://www.facebook.com/profile.php?id=61553857794715&mibextid=LQQJ4d",
        redirect: true,
        icon: Facebook
    },
    {

        name: "حسابنا على انستغرام",
        path: "https://www.instagram.com/alnashmi_restaurant?igsh=MXU4emRkc2lpZDg3dA%3D%3D&utm_source=qr",
        redirect: true,
        icon: Instagram
    }
]

function App() {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [expanded, setExpanded] = useState(false);

    const [isAppBarVisible, setAppBarVisibility] = useState(false);

    const handleOpenClick = () => {
        setAppBarVisibility(true);
    };

    const handleCloseClick = () => {
        setAppBarVisibility(false);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        setExpanded(false);
        navigate('/products/search', {
            state: {
                search: search
            }
        })
    }


    return (
        <Nav>
            <div className='container'>
                <div className='nav__content'>
                    <div onClick={() => {
                        navigate('/')
                    }}>
                        <img src={logo} width={130} height={39} alt='مطعم النشمي' />
                    </div>
                    <div onClick={handleOpenClick} className='menu__btn'>
                        <Menu />
                    </div>
                </div>
            </div>



            <AppBar>
                <div className={`${isAppBarVisible && 'show__menu'}`}>
                    <header>
                        <div className='img__container'>
                            <img src={sm_logo} loading='lazy' alt="مطعم النشمي" />
                        </div>
                        <div onClick={handleCloseClick}>
                            <XMark />
                        </div>
                    </header>

                    <NavMenu>
                        <div className='items__container'>
                            {items.map((item, index) => {
                                return (<div key={`nav_item_${index}`} onClick={() => {
                                    if (item.redirect) {
                                        window.location.replace(item.path);

                                    } else {
                                        navigate(item.path)
                                    }
                                }} className='nav__item'>
                                    <div className='text'>
                                        {item.name}
                                    </div>
                                    {item.icon ? <div className='icon'>
                                        <item.icon />
                                    </div> : null}

                                </div>)
                            })}

                        </div>
                        <form className="search__form" onSubmit={onSubmit}>
                            <input
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearch(e.target.value)
                                }}
                                placeholder="بحث"
                                aria-label="بحث"
                                type="search"
                                className='' />
                            <button aria-label="البحث" type="submit" className="search-btn">
                                <Search />
                            </button>
                        </form>
                    </NavMenu>
                </div>
                <div onClick={handleCloseClick} className={`overlay ${isAppBarVisible ? 'show__overlay' : ""}`}>
                </div>
            </AppBar>



        </Nav >
    );
}

export default App;
