import Skeleton from 'react-loading-skeleton';
import { SkeletonStyled } from './Styled.styled';

function SkeletonItem() {
    return (
        <SkeletonStyled>
            <Skeleton height={300} />
        </SkeletonStyled>

    )
}

export default SkeletonItem