import { scroller } from 'react-scroll';
import hero_img from 'src/assets/images/hero.png';
import Styled from './Styled.styled';
import hero_footer from 'src/assets/svg/hero_footer.svg';


function Hero() {
    return (
        <Styled>
            <div className='hero__content '>
                <div className='container'>
                    <div className='inline__container'>

                        <div className='content'>
                            <h1>
                                مطعم النشمي
                            </h1>
                            <p className=''>
                                يوقف معاك وقت تجوع
                            </p>
                            <button className='' onClick={() => {
                                scroller.scrollTo('categoriesElement', {
                                    duration: 600,
                                    delay: 50,
                                    smooth: true,
                                    offset: 50, // Scrolls to element + 50 pixels down the page
                                })
                            }}>
                                المينو
                            </button>
                        </div>
                        <div className='img__con'>
                            <img src={hero_img} alt="النشمي" loading='lazy' height={'fit-content'} width={490} />
                        </div>

                    </div>
                </div>

            </div>
            <img src={hero_footer} height={48} width={'100%'} alt="_" />
        </Styled>
    )
}

export default Hero