import 'src/assets/styles/bootstrap-grid.min.css';
import 'src/assets/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'



import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'





import App from './App';
import reportWebVitals from './reportWebVitals';

// Create a client
const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        cacheTime: 1000
        // staleTime: 1000 * 60 * 5
      },
    }
  }
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
