import { useNavigate } from "react-router-dom"
import Skeleton from 'react-loading-skeleton';
import Styled from './Styled.styled';

function Item({ item }: any) {
    const navigate = useNavigate();
    return (
        <Styled onClick={() => {
            navigate(`/categories/${item.slug}`, { state: { title: item.title } });
        }} >

            <div>
                <div className="img__con">
                    <img src={item.image} alt={item.title} loading="lazy" height={'fit-content'} width={'100%'} />
                </div>
                <div className='content'>
                    <h2>
                        {item.title}
                    </h2>
                </div>

            </div>

        </Styled>

    )
}

export default Item