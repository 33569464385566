import Styled from './Styled.styled';
import { XCircle } from '../icons';

function Error(props: { msg: string, iconColor?: string }) {
    return (
        <Styled>
            <div className='icon' style={{ color: props.iconColor ? props.iconColor : '#c05858' }}>
                <XCircle size={100} />
            </div>
            <div className='text'>
                {props.msg}
            </div>
        </Styled>)
}

export default Error