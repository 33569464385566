import React, { ReactHTMLElement, ReactSVGElement } from 'react'

type BaseProps = {
    children: React.SVGProps<SVGSVGElement>,
    props?: any
}

function Base({ children, props }: BaseProps) {

    return <div className='icon__container' {...props}>
        {children}
    </div>
}

export default Base