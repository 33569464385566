import { styled } from 'styled-components';


export default styled.div`
    background-color: rgba(51,51,51,.05);
    margin-bottom: 1rem;
    cursor:pointer;
    box-sizing: border-box;
    border-radius:7px;
    transition: background-color .4s ease;

    &>div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2.4rem 0;
        gap: 1rem;

    }
    
    .img__con{
        padding: 1rem;
        border-radius: 0.4rem;
        overflow: hidden;

        img{
            height: 136px;
            width: auto;
            transition:all .5s ease ;
        }
    }

    &:hover,
    &:active,
    &:focus{
        background-color: rgba(51,51,51,.12);

        img {  transform: scale(1.1); }
        }

    .content{
            display: flex;
            align-items: center;
            justify-content: center;
            transition:height .5s ease ;
            z-index: 999;

            h2{
                transition:all 1s ease ;
                font-family: var(--ft);
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 100%;
                text-align: right;
                color: #333333;
                letter-spacing: .5px;
            }
        }


    @media (max-width: 767px){ 
        .img__con img{
            height: 125px;
        }
        .content{
            /* height: 45%; */

            h2{
                font-size: 28px;
            }
        }
    }

    @media (max-width: 575px){    
        .img__con img{
            height: 135px;
        }

        .content{
            /* height: 50%; */
        }      
    }

    /* @media (max-width: 575px) and (min-width: 400px){
        .img-con{
           height: 270px; 
        }
    }*/

`


export const SkeletonStyled = styled.div`
    height: 300px;
    margin-bottom: .75rem;
`