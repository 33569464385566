import Image from 'src/assets/images/about.avif';
import { Title } from 'src/components';
import Styled from './Styled.styled';


function About() {
    return (
        <Styled>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <Title title={"نبذة عنا"} />
                    </div>
                    <div className='col-12 col-lg-5 pe-lg-5'>
                        <div className='img-con'>
                            <img src={Image} alt="About Us" width={300} height={300}
                                loading='lazy' />
                        </div>
                    </div>
                    <div className='col-12 col-lg-7 content-con ps-lg-5'>
                        <div className="content">

                            <p className='text'>
                                مطعم النشمي للشاورما والسندوتشات والوجبات المتنوعة نفخر بتقديم أشهى وألذ الأطعمة والوجبات لعشاق الشاورما فمطعمنا يجمع بين المذاق الشهي والجودة العالية في مطعم النشمي نسعى جاهدين لتقديم أفضل الخدمات والأطعمة للتأكد من نيل رضاكم .

                                <b> مطعمنا يرحب بكم
                                </b>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </Styled>
    )
}

export default About;