import { styled } from 'styled-components';


export default styled.section`
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color:var(--primary) ;
    
    
    .hero__content{
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        .inline__container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            top: 0;
            left: 0;

            .content{
                h1{
                    font-family: var(--ft);
                    font-style: normal;
                    font-weight: 800;
                    font-size: 64px;
                    line-height: 100%;
                    text-align: right;
                    color: #000000;
                    margin-bottom: 2.6rem;
                }

                p{
                    margin-top: 2.25rem;
                    font-family:var(--ft);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 100%;
                    text-align: right;
                    color: #333333;
                    letter-spacing: .5px;
                }
                
                button{
                    cursor: pointer;
                    margin-top: 4rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 176px;
                    height: 56px;
                    background: #FFFFFF;
                    border-radius: 200px;
                    border: unset;
                    font-family: var(--ft);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 100%;
                    letter-spacing: .5px;
                    color: #000000;
                    transition: all .4s ease;

                    &:hover,
                    &:active{
                        background: #000000;
                        color: #fff;
                    }
                }
            }

            .img__con{
                    width: 495px;
                    height: fit-content;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }

    }


    & > img {
        position: absolute;
        bottom: 0;
        height: 50px;
        width:100%;
        object-fit: cover;
        object-position: top center;
        animation: hero 8s ease-in-out .5s infinite alternate forwards;
    }

    @keyframes hero {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.15);
        }
    } 

    @media (max-width: 991px){
        height:550px;

        .hero__content{
            .inline__container{
                justify-content: center;

                .content{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    h1{
                        font-size: 64px;
                        line-height: 100%;
                        margin-bottom: 1.1rem;
                    }

                    p{
                        margin-top: 1.5rem;
                        margin-bottom: 1rem;
                        font-size: 32px;
                        line-height: 100%;
                        letter-spacing: .5px;
                    }
                    
                    button{
                        width: 156px;
                        height:46px;
                        margin-top: 2rem;
                        font-size: 18px;
                        line-height: 100%;
                        letter-spacing: .5px;
                    }
                }

                .img__con{
                        display: none;
                    }
                }

    }

        & > img {
            height: 45px;
        }

    }

    /* @media (max-width: 767px){} */

    @media (max-width: 575px){            
        height: 485px;

        .hero__content{
            .inline__container{
              
                .content{
                    h1{
                        font-size: 3.2rem;
                        line-height: 100%;
                    }

                    p{
                        margin-top: 1.4rem;
                        font-size: 2rem;
                        line-height: 100%;
                        letter-spacing: .5px;
                    }
                    
                    button{
                        margin-top: 2rem;
                        font-size: 18px;
                        line-height: 100%;
                        letter-spacing: .5px;
                    }
                }

                }

    }
         & > img {
            height: 40px;
        }
       
    }


    @media (max-width: 400px){            
        .hero__content{
            .inline__container{
              
                .content{
                    h1{
                        font-size: 2.8rem;
                        line-height: 100%;
                    }

                    p{
                        margin-top: 1.4rem;
                        font-size:1.7rem;
                        line-height: 100%;
                        letter-spacing: .5px;
                    }
                    
                    button{
                        width: 136px;
                        height:40px;
                        margin-top: 2rem;
                        font-size: 1rem;
                        line-height: 100%;
                        letter-spacing: .5px;
                    }
                }

                }

    }
       
    }

`