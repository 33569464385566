
import { PaginationStyled } from './Styled.styled';


const Pagination = ({ setPage, page, previous,
    pagesNum, next, result }: any) => {

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= pagesNum; i++) {
            pages.push(
                <span
                    key={`page-${i}`}
                    onClick={() => handlePageChange(i)}
                    className={`${page === i ? 'active' : ''} page__item`}
                >
                    {i}
                </span>
            );
        }
        return pages;
    };

    return (
        <PaginationStyled className={result < 1 ? 'd-none' : ''}>
            <span
                className={`${!previous ? 'd-none' : ''} page__item`}
                onClick={() => handlePageChange(page - 1)}
            >
                ‹
            </span>

            {renderPageNumbers()}

            <span
                className={`${!next ? 'd-none' : ""} page__item`}
                onClick={() => handlePageChange(page + 1)}
            >
                ›
            </span>
        </PaginationStyled >
    );
};

export default Pagination;
