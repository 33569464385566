import { styled } from 'styled-components';


export const Styled = styled.article`
    
    header{
        height: 300px;
        position: relative;
        object-fit: cover;
        object-position: center top;
        
        & > .title{ 
            display: flex;
            align-items:center;
            justify-content: center;
            position: relative;
            z-index: 9;
            height: 100%;
           

            h4{
                color: #fff;
                font-family: var(--ft);
                font-size: 3rem;
                line-height: 3rem;
                text-align: right;
                letter-spacing: 0.02em;
            }

        }

        img{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center top;
        }
    }

    section.container{
        padding: 6rem 0;

        .s-header{
            padding: 0 0 1.5rem 0;
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #DDDDDD;

            h4{
                margin-bottom: 0;
                font-family: var(--ft);
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 2.5rem;
                text-align: right;
                letter-spacing: 0.02em;
                color: #222222;
            }

            form {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-bottom: 0;

                input{
                    width: 300px;
                    padding: .6rem 1.5rem;
                    background: #F0F0F0;
                    border-radius: 30px;
                    border: unset;
                    font-family: var(--ft);
                    font-size: 1.1rem;
                    font-weight: 300;
                    letter-spacing: 0.02rem;
                    color: #222;
                }

                button {
                    background-color: var(--primary);
                    color: #fff;
                    padding: 0.5rem;
                    border-radius: 50%;
                    transition: background-color .2s ease-in-out;
                    border: none;

                    &:hover{
                        background-color: #002879; 
                    }
            }
        }
    }

    .not-found{
        font-family: var(--ft);
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #7F7F7F;
        text-align: center;
    }
    }

    .product{
     
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 1rem;
        gap: 1rem;

        .img-con{
            height: 125px;
            width: 125px;
            border-radius: 5px;
            overflow: hidden;

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .content{
            width: 60%;

            h4{
                margin-top: 0;
                font-family: var(--ft);
                font-style: normal;
                font-weight: 500;
                font-size: 1.08rem;
                line-height: 175%;
                /* or 28px */
                text-align: right;
                letter-spacing: 0.02em;
                color: #222222;
                margin-bottom: .1rem;
            }

            p{
                font-family: var(--ft);
                font-style: normal;
                font-weight: 400;
                font-size: .96rem;
                line-height: 100%;
                text-align: right;
                letter-spacing: 0.03em;
                color: #919191;
                margin-top: 0.4rem;
                margin-bottom: 0.7rem;
            }

            .price{
                display: flex;
                align-items: center;
                gap: .5rem;

                .original,
                .discount{
                    font-family: var(--ft);
                    font-style: normal;
                    line-height: 175%;
                    text-align: right;
                    letter-spacing: 0.02em;
                    color: #00A651;
                    font-weight: 600;
                }

                .discount{
                    font-size: 1rem;
                }

                .original_discount{
                    text-decoration-line: line-through;
                    font-family: var(--ft);
                    font-style: normal;
                    font-weight: 500;
                    color: #909090;
                    font-size: .9rem;
                }
            }
        }
    }


    @media screen and (max-width:768px){
        header{
            .title{
                h4{
                    font-size: 2.25rem;
                }
            }
        }

        section.container{
            .s-header{
                flex-wrap: wrap;
                justify-content: center;
                
                h4{
                    margin-bottom: 1.5rem;
                }
                form{
                    width: 100%;

                    input{
                        width: 90%;
                    }
                }
            }
        }
    }

    @media screen and (max-width:576px){
        section.container{
            padding: 4rem 1.25rem;
        }

        .product{
            margin: 0 1rem;
        }
    }
`





export const PaginationStyled = styled.div`
    display: flex;
    justify-content: center;
    gap: .5rem;
    margin-top: 1.5rem;


    .page__item{
        transition: all .3s ease;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F1F1F1;
        border-radius: 10px;
        color: #333;
        text-decoration: none;
        border: unset;
        transition: all .2s ease;
        
        &:hover{
            background: #dadada;
        }

        &:focus{
            box-shadow: unset;
        }
        }
                    
    .active{            
        color: #fff;
        background-color: var(--primary) !important;
    }


`

