import React from 'react';
import { QuestionMarkCircle } from '../icons';
import Styled from './Styled.styled';

function Page404() {
    return (
        <Styled>

            <QuestionMarkCircle size={100} />

            <div className='text'>
                لم يتم العثور على الصفحة
            </div>

        </Styled>
    )
}

export default Page404